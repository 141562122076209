import React from "react"
import { Box } from "@chakra-ui/react"

interface StepContextProps {
  next: () => void
  back: () => void
  setStep: (step: number) => void
}

const StepContext = React.createContext<StepContextProps | undefined>(undefined)

export interface StepProps {}

interface Props {
  children: React.ReactElement<StepProps, string>[]
  initialStep?: number
  hideProgress?: boolean
  progress?: number
  onNext?: (progress: number) => void
  onStepChange?: (progress: number) => void
  onBack?: (progress: number) => void
}

export const Steps: React.FC<Props> = ({
  children,
  initialStep,
  hideProgress = false,
  onBack,
  onNext,
  onStepChange,
  progress: controlledProgress,
}) => {
  const [progress, setProgress] = React.useState<number>(controlledProgress || initialStep || 1)
  const step = React.Children.map(children, (child) => child)[progress - 1]

  const handleNext = React.useCallback(() => {
    if (onNext) {
      onNext(progress + 1)
    } else {
      setProgress(progress + 1)
    }
    window.scroll(0, 0)
  }, [progress, onNext])

  const handleSetStep = React.useCallback(
    (step: number) => {
      if (onStepChange) {
        onStepChange(step)
      }
      setProgress(step)
      window.scroll(0, 0)
    },
    [onStepChange]
  )

  const handleBack = React.useCallback(() => {
    if (onBack) {
      onBack(progress - 1)
    } else {
      setProgress(progress - 1)
    }
    window.scroll(0, 0)
  }, [progress, onBack])

  const steps = React.Children.count(children)
  const percentage = Math.round((progress / steps) * 100)

  return (
    <StepContext.Provider value={{ next: handleNext, back: handleBack, setStep: handleSetStep }}>
      {steps > 1 && !hideProgress && (
        <Box pt={6} px={6} bg="white">
          <Box w="100%" h="4px" backgroundColor="gray.50" pos="relative">
            <Box pos="absolute" left={0} top={0} h="100%" w={percentage + "%"} backgroundColor="primary" />
          </Box>
        </Box>
      )}
      {step}
    </StepContext.Provider>
  )
}

export function useStep() {
  const context = React.useContext(StepContext)
  if (!context) throw new Error("Must be called under a StepContextProvider")
  return context
}
