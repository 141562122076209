import * as React from "react"
import { Box, Divider, Flex, SimpleGrid, Text } from "@chakra-ui/layout"
import {
  chakra,
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useEmblaCarousel from "embla-carousel-react"
import { BiMinus, BiPlus } from "react-icons/bi"
import { graphql, navigate } from "gatsby"
import { RichText } from "prismic-reactjs"

import Layout from "src/components/Layout"
import { Steps, useStep } from "src/components/Steps"
import backgroundImg from "../../images/advice-bg.png"
import { PrevButton, NextButton } from "src/components/Embla"
import { Passenger } from "src/components/Icons"
import { adviceDataToSearchParams, searchParamstoQueryString } from "src/lib/helpers/search-params"
import { useLocalStorageState } from "src/gatsby/useSessionStorageState"
import { ImageDataLike } from "gatsby-plugin-image"

const CImg = chakra(GatsbyImage)

interface Filters {
  country: Location | null
  sailingArea: Location | null
  passengers: number
  maxPrice: number | null
  boatType: string | null
  email: string
}

const defaultFilters: Filters = {
  country: null,
  sailingArea: null,
  passengers: 0,
  maxPrice: 0,
  boatType: null,
  email: "",
}

interface Boat {
  uid: string
  data: {
    name: string
    image: ImageDataLike
    best_for: { richText: any[] }
  }
}

interface BoatNode {
  nodes: Array<Boat>
}
interface Location {
  uid: string
  data: {
    name: string
    preview_image: ImageDataLike
    sailing_time: { richText: any[] }
    country: {
      uid: string | null
    }
  }
}

interface LocationNode {
  nodes: Array<Location>
}

interface Props {
  data: {
    allPrismicLocation: LocationNode
    allPrismicBoat: BoatNode
  }
}

const normalizeSailingAreas = (locations: Location[]) => {
  return locations.reduce((acc, location) => {
    if (!location.data.country.uid) {
      // If a country
      if (!acc[location.uid]) {
        acc[location.uid] = location
      } else {
        acc[location.uid] = { ...location, ...acc[location.uid] }
      }
    } else {
      // If a sailing area
      if (!acc[location.data.country.uid]) {
        acc[location.data.country.uid] = { uid: location.data.country.uid }
      }
      if (acc[location.data.country.uid].sailingAreas) {
        if (acc[location.data.country.uid].sailingAreas.find((sa: Location) => sa.uid === location.uid)) return acc
        acc[location.data.country.uid].sailingAreas = [...acc[location.data.country.uid].sailingAreas, location]
      } else {
        acc[location.data.country.uid].sailingAreas = [location]
      }
    }
    return acc
  }, {} as any)
}

export default function Advice({ data }: Props) {
  const locations = data.allPrismicLocation
  const boatTypes = data.allPrismicBoat
  const countries = React.useMemo(
    () => normalizeSailingAreas(locations.nodes),
    // eslint-disable-next-line
    []
  )

  const [emailFromSession, setEmailFromSession] = useLocalStorageState("email")
  const [filters, setFilters] = React.useState<typeof defaultFilters>({
    ...defaultFilters,
    email: emailFromSession || "",
  })

  const handleSetFilters = (filter: { [key: string]: any }) => {
    setFilters((f) => ({ ...f, ...filter }))
  }
  return (
    <Layout noHero invertedNav>
      <Box bg={`url(${backgroundImg})`} h="90vh" w="100%" backgroundPosition="center" backgroundSize="cover">
        <Box w="90%" maxW="1000px" mx="auto">
          <Box pt={{ base: 6, md: 10 }}>
            <Steps>
              <CountryStep filters={filters} setFilters={handleSetFilters} countries={countries} />
              <SailingAreaStep
                filters={filters}
                setFilters={handleSetFilters}
                sailingAreas={filters.country ? countries[filters.country.uid].sailingAreas || [] : []}
              />
              {/* <HolidayTypeStep filters={filters} setFilters={handleSetFilters} /> */}
              <GuestsStep filters={filters} setFilters={handleSetFilters} />
              <PriceStep filters={filters} setFilters={handleSetFilters} />
              <BoatTypeStep filters={filters} setFilters={handleSetFilters} boatTypes={boatTypes.nodes} />
              <EmailStep
                filters={filters}
                setFilters={handleSetFilters}
                setEmailFromSession={setEmailFromSession}
                sailingAreas={filters.country ? countries[filters.country.uid].sailingAreas || [] : []}
              />
            </Steps>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

interface StepProps {
  filters: typeof defaultFilters
  setFilters: (filter: Partial<Filters>) => void
}

function Question(props: { children: React.ReactNode }) {
  return (
    <Box mb={4} p={{ base: 4, md: 10 }} bg="white">
      <Text textAlign="center" color="primary" fontSize="lg">
        {props.children}
      </Text>
    </Box>
  )
}

interface CountryProps extends StepProps {
  countries: { [key: string]: Location }
}

function CountryStep(props: CountryProps) {
  const countries = Object.entries(props.countries).map(([key, country]) => ({ value: key, ...country }))
  const { next, setStep } = useStep()
  const isMobile = useBreakpointValue({ base: true, sm: false })

  // Only update the index on mount
  // eslint-disable-next-line
  const startIndex = React.useMemo(() => countries.findIndex((c) => c.uid === props.filters.country?.uid), [])

  const [emblaRef, embla] = useEmblaCarousel({
    draggable: isMobile,
    slidesToScroll: 1,
    align: "center",
    containScroll: "trimSnaps",
    startIndex,
  })

  const scrollPrev = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (embla) embla.scrollPrev()
    },
    [embla]
  )

  const scrollNext = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (embla) embla.scrollNext()
    },
    [embla]
  )

  const handleChooseNone = () => {
    props.setFilters({ country: null })
    setStep(3)
  }

  return (
    <Box>
      <Question>Which country do you want to go sailing in?</Question>
      <Box pos="relative">
        <Box className="embla" pos="relative" sx={{ button: { display: "flex" } }}>
          <Box overflow="hidden" w="100%" ref={emblaRef}>
            <Flex pos="relative" ml={-4}>
              {countries.map((country) => (
                <Box key={country.value} maxW="33.33%" minW={isMobile ? "100%" : "33.33%"} pl={4}>
                  <Box role="button" onClick={() => props.setFilters({ country })} bg="white">
                    <Flex p={2} align="center" justify="space-between">
                      <Center borderRadius="full" boxSize="24px" border="2px solid" borderColor="gray.200">
                        {props.filters.country?.uid === country.value && (
                          <Box borderRadius="6px" boxSize="12px" bg="primary" />
                        )}
                      </Center>
                      <Text color="primary" fontSize="sm">
                        {country.data.name}
                      </Text>
                      <Box boxSize="24px" />
                    </Flex>
                    <CImg image={getImage(country.data.preview_image)} h={150} w="100%" />

                    <Text p={2} fontSize="sm" color="primary">
                      <Text as="span" fontWeight="bold">
                        Best time for sailing:{" "}
                      </Text>
                      <Text as="span">{RichText.asText(country.data.sailing_time.richText)}</Text>
                    </Text>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Box>
        </Box>
        <PrevButton onClick={scrollPrev} left={{ base: "10px", md: "-40px" }} display="block" />
        <NextButton onClick={scrollNext} right={{ base: "10px", md: "-40px" }} display="block" />
      </Box>
      <Center mt={4} color="primary" role="button" p={4} bg="white" fontSize="lg" onClick={handleChooseNone}>
        I don't mind, choose for me!
      </Center>
      <Flex justify="space-between" mt={4}>
        <Box />
        <Button onClick={next} isDisabled={!!!props.filters.country}>
          Next
        </Button>
      </Flex>
    </Box>
  )
}

interface SailingAreaProps extends StepProps {
  sailingAreas: Location[]
}
function SailingAreaStep(props: SailingAreaProps) {
  const sailingAreas = props.sailingAreas

  const { next, back } = useStep()
  const isMobile = useBreakpointValue({ base: true, sm: false })

  // Only update the index on mount
  // eslint-disable-next-line
  const startIndex = React.useMemo(() => sailingAreas.findIndex((c) => c.uid === props.filters.sailingArea?.uid), [])
  const [emblaRef, embla] = useEmblaCarousel({
    draggable: isMobile,
    slidesToScroll: 1,
    align: "center",
    containScroll: "trimSnaps",
    startIndex,
  })

  const scrollPrev = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (embla) embla.scrollPrev()
    },
    [embla]
  )

  const scrollNext = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (embla) embla.scrollNext()
    },
    [embla]
  )

  const handleChooseNone = () => {
    props.setFilters({ sailingArea: null })
    next()
  }

  const handleNext = () => {
    next()
  }
  return (
    <Box>
      <Question>Where in {props.filters.country?.data.name} do you want to go sailing?</Question>
      <Box pos="relative">
        <Box className="embla" pos="relative" sx={{ button: { display: "flex" } }}>
          <Box overflow="hidden" w="100%" ref={emblaRef}>
            <Flex pos="relative" ml={-4}>
              {sailingAreas.map((sailingArea) => (
                <Box key={sailingArea.uid} maxW="33.33%" minW={isMobile ? "100%" : "33.33%"} pl={4}>
                  <Box role="button" onClick={() => props.setFilters({ sailingArea })} bg="white">
                    <Flex p={2} align="center" justify="space-between">
                      <Center borderRadius="full" boxSize="24px" border="2px solid" borderColor="gray.200">
                        {props.filters.sailingArea?.uid === sailingArea.uid && (
                          <Box borderRadius="6px" boxSize="12px" bg="primary" />
                        )}
                      </Center>
                      <Text color="primary" fontSize="sm">
                        {sailingArea.data.name}
                      </Text>
                      <Box boxSize="24px" />
                    </Flex>
                    <CImg image={getImage(sailingArea.data.preview_image)} h={150} w="100%" />

                    <Text p={2} fontSize="sm" color="primary">
                      <Text as="span" fontWeight="bold">
                        Best time for sailing:{" "}
                      </Text>
                      <Text as="span">{RichText.asText(sailingArea.data.sailing_time.richText)}</Text>
                    </Text>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Box>
        </Box>
        <PrevButton onClick={scrollPrev} left={{ base: "10px", md: "-40px" }} display="block" />
        <NextButton onClick={scrollNext} right={{ base: "10px", md: "-40px" }} display="block" />
      </Box>
      <Center mt={4} role="button" color="primary" p={4} bg="white" fontSize="lg" onClick={handleChooseNone}>
        I don't mind, choose for me!
      </Center>
      <Flex justify="space-between" mt={4}>
        <Button onClick={back} variant="clear">
          Back
        </Button>
        <Button onClick={handleNext} isDisabled={!!!props.filters.sailingArea}>
          Next
        </Button>
      </Flex>
    </Box>
  )
}

// function HolidayTypeStep(props: StepProps) {
//   const { next, setStep, back } = useStep()

//   const handleBack = () => {
//     if (props.filters.country === null) return setStep(1)
//     return back()
//   }

//   const handleNext = (type: "relax" | "chilling" | "party") => {
//     // TODO: need to decide on these values
//     // const sailingArea = props.filters.country ? adviceSailingOptions.filter((c)=>c.country === props.filters.country?.uid)[0].sailingArea : null
//     switch (type) {
//       case "relax":
//         props.setFilters({ country: null, sailingArea: null })
//         break
//       case "chilling":
//         props.setFilters({ country: null, sailingArea: null })
//         break
//       case "party":
//         props.setFilters({ country: null, sailingArea: null })
//         break
//       default:
//         props.setFilters({ country: null, sailingArea: null })
//         break
//     }
//     next()
//   }

//   return (
//     <Box>
//       <Question>What are you looking for from your sailing holiday?</Question>
//       <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
//         <Center textAlign="center" role="button" bg="white" p={6} onClick={() => handleNext("relax")}>
//           Mostly relax & Soak up the sun
//         </Center>
//         <Center textAlign="center" role="button" bg="white" p={6} onClick={() => handleNext("chilling")}>
//           Combination of sightseeing & Chilling
//         </Center>
//         <Center textAlign="center" role="button" bg="white" p={6} onClick={() => handleNext("party")}>
//           Saling from party location to another
//         </Center>
//       </SimpleGrid>
//       <Flex justify="space-between" mt={4}>
//         <Button onClick={handleBack} variant="clear">
//           Back
//         </Button>
//       </Flex>
//     </Box>
//   )
// }

function GuestsStep(props: StepProps) {
  const { back, next, setStep } = useStep()

  const increment = () => {
    if (props.filters.passengers === 20) return
    props.setFilters({ passengers: props.filters.passengers + 1 })
  }
  const decrement = () => {
    if (props.filters.passengers === 0) return
    props.setFilters({ passengers: props.filters.passengers - 1 })
  }

  const handleBack = () => {
    if (!props.filters.country) return setStep(1)
    back()
  }

  return (
    <Box>
      <Question>How many people are in your group?</Question>

      <Center>
        <HStack spacing={6} bg="white" p={6}>
          <Passenger sx={{ fill: "primary" }} boxSize={6} />
          <IconButton
            aria-label="decrement"
            isDisabled={props.filters.passengers === 0}
            icon={<Box as={BiMinus} />}
            onClick={decrement}
            size="xs"
            borderRadius="full"
            variant="outline"
          />
          <Text color={props.filters.passengers === 0 ? "muted" : "black"}>{props.filters.passengers}</Text>
          <IconButton
            aria-label="increment"
            icon={<Box as={BiPlus} />}
            onClick={increment}
            size="xs"
            borderRadius="full"
            variant="outline"
          />
        </HStack>
      </Center>
      <Flex justify="space-between" mt={4}>
        <Button onClick={handleBack} variant="clear">
          Back
        </Button>
        <Button onClick={next} isDisabled={!!!props.filters.passengers}>
          Next
        </Button>
      </Flex>
    </Box>
  )
}
function PriceStep(props: StepProps) {
  const { back, next } = useStep()

  const handleNotSure = () => {
    props.setFilters({ maxPrice: null })
    next()
  }

  const handleSetPrice = (data: string) => {
    try {
      const val = parseInt(data, 10)
      props.setFilters({ maxPrice: val })
    } catch {}
  }
  return (
    <Box>
      <Question>What is your budget per week?</Question>

      <SimpleGrid spacing={{ base: 4, md: 10 }} columns={{ base: 1, md: 2 }}>
        <Stack bg="white" p={6} spacing={4}>
          <InputGroup size="lg">
            <InputLeftElement pointerEvents="none" color="primary" children="€" />
            <Input
              type="number"
              placeholder="Type here"
              borderRadius={0}
              min={1}
              value={props.filters.maxPrice || ""}
              onChange={(e) => handleSetPrice(e.target.value)}
            />
          </InputGroup>
          <Divider />
          <Text fontSize="sm" color="gray.500">
            Budget Idea: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur quis purus tempor, ornare
            urna quis, rutrum.
          </Text>
        </Stack>
        <Center bg="white" role="button" color="primary" p={10} onClick={handleNotSure}>
          <Text>Not sure yet</Text>
        </Center>
      </SimpleGrid>
      <Flex justify="space-between" mt={4}>
        <Button onClick={back} variant="clear">
          Back
        </Button>
        <Button onClick={next} isDisabled={!!!props.filters.maxPrice}>
          Next
        </Button>
      </Flex>
    </Box>
  )
}

interface BoatProps extends StepProps {
  boatTypes: Boat[]
}

function BoatTypeStep(props: BoatProps) {
  const boatTypes = props.boatTypes

  const { next, back } = useStep()
  const isMobile = useBreakpointValue({ base: true, sm: false })

  // Only update the index on mount
  // eslint-disable-next-line
  const startIndex = React.useMemo(() => boatTypes.findIndex((c) => c.uid === props.filters.boatType), [])
  const [emblaRef, embla] = useEmblaCarousel({
    draggable: isMobile,
    slidesToScroll: 1,
    align: "center",
    containScroll: "trimSnaps",
    startIndex,
  })

  const scrollPrev = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (embla) embla.scrollPrev()
    },
    [embla]
  )

  const scrollNext = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (embla) embla.scrollNext()
    },
    [embla]
  )

  const handleChooseNone = () => {
    props.setFilters({ boatType: null })
    next()
  }

  return (
    <Box>
      <Question>Do you have a boat preference?</Question>
      <Box pos="relative">
        <Box className="embla" pos="relative" sx={{ button: { display: "flex" } }}>
          <Box overflow="hidden" w="100%" ref={emblaRef}>
            <Flex pos="relative" ml={-4}>
              {boatTypes.map((boatType) => (
                <Box key={boatType.uid} maxW="33.33%" minW={isMobile ? "100%" : "33.33%"} pl={4}>
                  <Box role="button" onClick={() => props.setFilters({ boatType: boatType.uid })} bg="white">
                    <Flex p={2} align="center" justify="space-between">
                      <Center borderRadius="full" boxSize="24px" border="2px solid" borderColor="gray.200">
                        {props.filters.boatType === boatType.uid && (
                          <Box borderRadius="6px" boxSize="12px" bg="primary" />
                        )}
                      </Center>
                      <Text color="primary" fontSize="sm">
                        {boatType.data.name}
                      </Text>
                      <Box boxSize="24px" />
                    </Flex>
                    <CImg image={getImage(boatType.data.image)} h={150} w="100%" />
                    <Text p={2} fontSize="sm" color="primary">
                      <Text as="span" fontWeight="bold">
                        Best for:{" "}
                      </Text>
                      <Text as="span">{RichText.asText(boatType.data.best_for.richText)}</Text>
                    </Text>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Box>
        </Box>
        <PrevButton onClick={scrollPrev} left={{ base: "10px", md: "-40px" }} display="block" />
        <NextButton onClick={scrollNext} right={{ base: "10px", md: "-40px" }} display="block" />
      </Box>
      <Center mt={4} role="button" color="primary" p={4} bg="white" fontSize="lg" onClick={handleChooseNone}>
        I don't mind, choose for me!
      </Center>
      <Flex justify="space-between" mt={4}>
        <Button onClick={back} variant="clear">
          Back
        </Button>
        <Button onClick={next} isDisabled={!!!props.filters.boatType}>
          Next
        </Button>
      </Flex>
    </Box>
  )
}

interface EmailStepProps extends StepProps {
  sailingAreas: Location[]
  setEmailFromSession: (email: string) => void
}
function EmailStep(props: EmailStepProps) {
  const { back } = useStep()

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const filters: any = { ...props.filters }
    if (!props.filters.sailingArea && props.filters.country) {
      // TODO: which sailing area do we pick from selected country?
      filters.sailingArea = props.sailingAreas[0]
    } else if (!props.filters.country) {
      // TODO: which country and sailing area do we pick?
      filters.sailingArea = { uid: "sicily" }
    }
    if (!props.filters.boatType) {
      // TODO: default boat type?
      filters.boatType = "catamaran"
    }
    const params = adviceDataToSearchParams(filters)
    const qs = searchParamstoQueryString(params)
    props.setEmailFromSession(filters.email)
    navigate("/advice/results?" + qs)
  }

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Question>Let us send you the best boats meeting your needs</Question>

      <Center textAlign="center" role="button" bg="white" p={6}>
        <InputGroup size="lg">
          <InputLeftElement
            pointerEvents="none"
            w={20}
            children={
              <Text color="primary" fontSize="22px" fontWeight="semibold" textAlign="center">
                Email
              </Text>
            }
          />
          <Input
            placeholder="example@gmail.com"
            borderRadius={0}
            pl={20}
            isRequired
            border={0}
            value={props.filters.email || ""}
            onChange={(e) => props.setFilters({ email: e.target.value })}
          />
        </InputGroup>
      </Center>

      <Flex justify="space-between" mt={4}>
        <Button type="button" onClick={back} variant="clear">
          Back
        </Button>
        <Button type="submit" isDisabled={!!!props.filters.email}>
          Done
        </Button>
      </Flex>
    </Box>
  )
}

export const query = graphql`
  query AdviceQuery {
    allPrismicLocation(sort: { order: ASC, fields: data___name }) {
      nodes {
        data {
          name
          preview_image {
            gatsbyImageData(height: 300)
          }
          sailing_time {
            richText
          }
          country {
            uid
          }
        }
        uid
      }
    }
    allPrismicBoat {
      nodes {
        data {
          name
          image {
            gatsbyImageData(height: 300)
          }
          best_for {
            richText
          }
        }
        uid
      }
    }
  }
`
